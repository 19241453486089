"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.firebaseApp = void 0;
/* eslint-disable vue/multi-word-component-names */
require("./assets/main.css");
var vue_1 = require("vue");
var vuefire_1 = require("vuefire");
var app_1 = require("firebase/app");
// import App from './App.vue'
var ChatWindow_vue_1 = require("./ChatWindow.vue");
var config_1 = require("primevue/config");
var card_1 = require("primevue/card");
var button_1 = require("primevue/button");
var inputtext_1 = require("primevue/inputtext");
var avatar_1 = require("primevue/avatar");
var textarea_1 = require("primevue/textarea");
var inlinemessage_1 = require("primevue/inlinemessage");
var message_1 = require("primevue/message");
require("./assets/main.css");
require("primevue/resources/themes/lara-light-green/theme.css");
require("primeflex/primeflex.css");
require("primevue/resources/primevue.min.css");
require("primeicons/primeicons.css");
// let reCaptchaEnterpriseProviderKey = '';
if (import.meta.env) {
    exports.firebaseApp = (0, app_1.initializeApp)({
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIREBASE_APP_ID,
        measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    });
    // reCaptchaEnterpriseProviderKey = import.meta.env.VITE_RECAPTCHA_ENTERPRISE_PROVIDER_KEY;
}
else {
    exports.firebaseApp = (0, app_1.initializeApp)({
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '',
        appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || ''
    });
    // reCaptchaEnterpriseProviderKey = process.env.VUE_APP_RECAPTCHA_ENTERPRISE_PROVIDER_KEY || '';
}
var app = (0, vue_1.createApp)(ChatWindow_vue_1.default);
app.use(config_1.default);
app.use(vuefire_1.VueFire, {
    firebaseApp: exports.firebaseApp,
    modules: [
        (0, vuefire_1.VueFireAuth)(),
        // VueFireAppCheck({
        //   provider: new ReCaptchaEnterpriseProvider(reCaptchaEnterpriseProviderKey),
        //   isTokenAutoRefreshEnabled: true,
        //   debug: process.env.NODE_ENV !== 'production'
        // })
    ]
});
app.component('Card', card_1.default);
app.component('PrimeButton', button_1.default);
app.component('InputText', inputtext_1.default);
app.component('Avatar', avatar_1.default);
app.component('PrimeTextarea', textarea_1.default);
app.component('InlineMessage', inlinemessage_1.default);
app.component('Message', message_1.default);
var widgetMountPoint = document.getElementById('aspiire-chatbot-app');
if (!widgetMountPoint) {
    widgetMountPoint = document.createElement('div');
}
document.body.style.background = 'transparent';
var chatbotId = widgetMountPoint.getAttribute('chatbot-id');
var initialViewSize = widgetMountPoint.getAttribute('initial-view-size');
var embed = widgetMountPoint.getAttribute('embed') || false;
var minimized = widgetMountPoint.getAttribute('minimized') || false;
var queryParams = getQueryParams();
if (embed) {
    app.config.globalProperties.$embed = embed;
}
else {
    app.config.globalProperties.$embed = queryParams.embed || false;
}
if (chatbotId) {
    app.config.globalProperties.$botId = chatbotId;
}
else {
    app.config.globalProperties.$botId = queryParams.botId;
}
if (initialViewSize) {
    app.config.globalProperties.$viewSize = initialViewSize;
}
else {
    app.config.globalProperties.$viewSize = queryParams.viewSize;
}
if (minimized) {
    app.config.globalProperties.$minimized = minimized;
}
else {
    app.config.globalProperties.$minimized = queryParams.minimized;
}
document.body.appendChild(widgetMountPoint);
app.mount(widgetMountPoint);
function getQueryParams() {
    var params = new URLSearchParams(window.location.search);
    return {
        botId: params.get('botId'),
        viewSize: params.get('viewSize'),
        embed: params.get('embed'),
        minimized: params.get('minimized'),
    };
}
